<template>
  <div class="client-algolia">
    <UiContainer large with-actions>
      <form class="client-algolia__form" @submit.prevent="onSubmit" novalidate autocomplete="off">
        <section class="client-algolia__form__section">
          <UiTitle :title="$t('clients.id.algolia.general.title')" />

          <div class="client-algolia__form__section__grid">
            <ui-block
              id="algoliaRulesLabel"
              class="required-asterisk"
              :label="$t('clients.id.algolia.general.algoliaRules.label')"
            >
              <template v-slot:body>
                <v-textarea
                  v-model="algoliaRulesModel"
                  id="algoliaRules"
                  solo
                  flat
                  outlined
                  auto-grow
                  no-resize
                  :placeholder="$t('clients.id.algolia.general.algoliaRules.placeholder')"
                  :prepend-inner-icon="icons.mdiNoteEdit"
                  :error-messages="algoliaRulesErrors"
                />
              </template>
            </ui-block>
          </div>
        </section>
        <UiActions large centered>
          <v-btn type="submit" rounded color="primary" :loading="saving" :disabled="saving">
            {{ $t('button.save') }}
          </v-btn>
        </UiActions>
      </form>
    </UiContainer>
  </div>
</template>

<script>
import { mdiNoteEdit } from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { clone } from '@/utils/utilities.util'
import UiActions from '@/components/UI/Actions.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiBlock from '@/components/UI/Block.vue'

export default {
  name: 'ClientAlgolia',
  components: {
    UiActions,
    UiContainer,
    UiTitle,
    UiBlock,
  },
  mixins: [validationMixin],
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentClient: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiNoteEdit,
    },
    clientModel: {},
    algoliaRulesModel: '',
  }),
  mounted() {
    this.clientModel = clone(this.currentClient)
    this.algoliaRulesModel = JSON.stringify(this.clientModel.algoliaRules)
  },
  computed: {
    algoliaRulesErrors() {
      const errors = []
      if (!this.$v.algoliaRulesModel.$dirty) return errors
      !this.$v.algoliaRulesModel.required && errors.push(this.$t('error.required'))
      !this.$v.algoliaRulesModel.isJson && errors.push(this.$t('error.json'))
      return errors
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.clientModel.algoliaRules = JSON.parse(this.algoliaRulesModel)
        this.$emit('updateClient', { client: this.clientModel })
        this.$v.$reset()
      }
    },
  },
  validations() {
    return {
      algoliaRulesModel: {
        required,
        isJson: json => {
          try {
            JSON.parse(json)
            return true
          } catch {
            return false
          }
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.client-algolia {
  @apply tw-h-full;

  &__form {
    &__section {
      @apply tw-mt-6;
      &:first-child {
        @apply tw-mt-0;
      }
      &__grid {
        @apply tw-grid tw-gap-x-6 tw-w-full tw-mt-6;
      }
    }
  }
}
</style>
