<template>
  <ui-modal
    class="ui-modal-url-locator"
    :title="$t('modal.urlLocator.title')"
    :subtitle="$t('modal.urlLocator.subtitle')"
    :dialog="true"
    @save="save"
    @close="close"
  >
    <template v-slot:body>
      <div class="ui-modal-url-locator__body">
        <div class="ui-modal-url-locator__body__grid">
          <ui-block id="fo" :label="$t('modal.urlLocator.fo.label')">
            <template v-slot:body>
              <v-autocomplete
                v-model="dataModel.foID"
                id="FOLabel"
                solo
                flat
                dense
                outlined
                hide-details
                item-text="name"
                item-value="id"
                :items="availablesFrontOffices"
                :placeholder="$t('modal.urlLocator.fo.placeholder')"
              />
            </template>
          </ui-block>
          <ui-block id="locale" :label="$t('modal.urlLocator.locale.label')">
            <template v-slot:body>
              <v-autocomplete
                v-model="dataModel.localeCode"
                id="localeLabel"
                solo
                flat
                dense
                outlined
                hide-details
                item-text="localeName"
                item-value="localeCode"
                :items="selectFo.locales"
                :placeholder="$t('modal.urlLocator.locale.placeholder')"
              />
            </template>
          </ui-block>
        </div>
        <ui-block id="websiteSuffix" :label="$t('modal.urlLocator.websiteSuffix.label')">
          <template v-slot:body>
            <v-text-field
              hide-details
              dense
              v-model="dataModel.suffix"
              id="websiteSuffixLabel"
              solo
              flat
              outlined
              :placeholder="$t('modal.urlLocator.websiteSuffix.placeholder')"
            />
          </template>
        </ui-block>
        <ui-block id="generateUrl" :label="$t('modal.urlLocator.generateUrl.label')">
          <template v-slot:body>
            <v-text-field :value="generateUrl" readonly dense id="generateUrlLabel" solo flat />
          </template>
        </ui-block>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn rounded plain @click="close">
        {{ $t('button.cancel') }}
      </v-btn>
      <v-btn rounded color="primary" @click="save">
        {{ $t('button.validate') }}
      </v-btn>
    </template>
  </ui-modal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { clone } from '@/utils/utilities.util'
import UiModal from '@/components/UI/Modal.vue'
import UiBlock from '@/components/UI/Block.vue'

export default {
  name: 'UiModalUrlLocator',
  components: {
    UiModal,
    UiBlock,
  },
  mixins: [validationMixin],
  props: {
    data: {
      type: Object,
      required: false,
    },
    availablesFrontOffices: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    dataModel: {},
  }),
  mounted() {
    this.dataModel = clone(this.data)
  },
  computed: {
    selectFo() {
      return this.availablesFrontOffices.find(fo => fo.id === this.dataModel.foID) || {}
    },
    selectLocale() {
      return this.selectFo.locales?.find(locale => locale.localeCode === this.dataModel.localeCode) || {}
    },
    generateUrl() {
      return `${this.selectFo.url || '{locatorUrl}'}/${this.dataModel.localeCode || '{locale}'}/{locationID}?${
        this.dataModel.suffix
      }`
    },
  },
  methods: {
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('save', this.dataModel)
      }
    },
    remove() {
      this.$emit('remove', this.dataModel)
    },
    close() {
      this.$emit('close')
    },
  },
  validations() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.ui-modal-url-locator {
  &__body {
    @apply tw-flex tw-flex-col tw-gap-4;

    &__grid {
      @apply tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-gap-y-4 tw-w-full;
    }
  }
}
</style>
