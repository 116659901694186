<template>
  <div class="client-oh-groups">
    <UiContainer large with-actions>
      <form class="client-oh-groups__form" @submit.prevent="onSubmit" novalidate autocomplete="off">
        <section class="client-oh-groups__form__section">
          <ui-switch
            :label="$t('clients.id.ohGroups.status.title')"
            :switch-status="locationOHStatus"
            :icon="icons.mdiStoreClockOutline"
            @onChange="updateLocationOHStatus"
          ></ui-switch>
        </section>
        <section class="client-oh-groups__form__section tw-mb-6">
          <UiTitle :title="$t('clients.id.ohGroups.rules.title')" />

          <div class="tw-flex tw-flex-col tw-mt-6">
            <v-card
              v-for="rule in clientModel.locationOpeningHoursRules"
              :key="rule.id"
              class="client-oh-groups__form__section__rule"
            >
              <v-icon>{{ icons.mdiSelectGroup }}</v-icon>
              <div>
                <UiLabel id="name" :label="rule.name" />
                <UiSubtitle :subtitle="parsedWeekdays(rule.weekdays)" />
              </div>
            </v-card>
            <v-btn
              class="tw-self-center tw-mt-4"
              color="primary"
              rounded
              outlined
              @click="openModalOhRule"
              v-if="locationOHStatus"
            >
              <v-icon dense left> {{ icons.mdiPlus }} </v-icon>
              {{ $t('clients.id.ohGroups.rules.button') }}
            </v-btn>
          </div>
        </section>
        <UiActions large centered>
          <v-btn type="submit" rounded color="primary" :loading="saving" :disabled="saving">
            {{ $t('button.save') }}
          </v-btn>
        </UiActions>
      </form>
    </UiContainer>

    <ui-modal-oh-rule v-if="editingModal === 'ohrule'" @save="saveModalOhRule" @close="closeModal" />
  </div>
</template>

<script>
import { mdiStoreClockOutline, mdiSelectGroup, mdiPlus } from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { clone } from '@/utils/utilities.util'
import UiActions from '@/components/UI/Actions.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiLabel from '@/components/UI/Label.vue'
import UiModalOhRule from '@/components/UI/Modal/OhRule.vue'
import UiSwitch from '@/components/UI/Switch.vue'

export default {
  name: 'ClientOhGroups',
  components: {
    UiActions,
    UiContainer,
    UiTitle,
    UiSubtitle,
    UiLabel,
    UiModalOhRule,
    UiSwitch,
  },
  mixins: [validationMixin],
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentClient: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiStoreClockOutline,
      mdiSelectGroup,
      mdiPlus,
    },
    editingModal: '',
    clientModel: {},
  }),
  mounted() {
    this.clientModel = clone(this.currentClient)
  },
  computed: {
    locationOHStatus() {
      return this.clientModel.locationOpeningHoursStatus === '1' ? true : false
    },
  },
  methods: {
    parsedWeekdays(weekdays) {
      let parsedWeekdays = ''
      weekdays.forEach(day => {
        if (parsedWeekdays !== '') {
          parsedWeekdays += ', ' + this.$t(`common.days.${day}`)
        } else {
          parsedWeekdays = this.$t(`common.days.${day}`)
        }
      })
      return parsedWeekdays
    },
    openModalOhRule() {
      this.editingModal = 'ohrule'
    },
    updateLocationOHStatus(status) {
      this.clientModel.locationOpeningHoursStatus = status ? '1' : '0'
    },
    saveModalOhRule(payload) {
      if (this.clientModel.locationOpeningHoursRules) {
        this.clientModel.locationOpeningHoursRules.push(payload)
      } else {
        this.clientModel.locationOpeningHoursRules = [payload]
      }
      this.closeModal()
    },
    closeModal() {
      this.editingModal = ''
      this.modalData = {}
    },
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('updateClient', { client: this.clientModel })
        this.$v.$reset()
      }
    },
  },

  validations() {
    return {
      clientModel: {
        locationOpeningHoursStatus: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.client-oh-groups {
  @apply tw-h-full;

  &__form {
    &__section {
      @apply tw-mt-12;
      &:first-child {
        @apply tw-mt-0;
      }

      &__status {
        @apply tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-6;

        &__left {
          @apply tw-flex tw-flex-row tw-items-center tw-gap-4;

          &__icon {
            @apply tw-rounded-full tw-p-1;

            border: 1px solid;
          }
        }
      }

      &__rule {
        @apply tw-flex tw-flex-row tw-gap-4 tw-my-2 tw-p-4 tw-shadow;

        &:first-child {
          @apply tw-mt-0;
        }
      }
    }
  }
}

.active {
  color: var(--v-success-base);
}
.inactive {
  color: var(--v-error-base);
}
</style>
