<template>
  <ui-modal
    class="ui-modal-feature"
    :title="$t('modal.ohrule.title')"
    :subtitle="$t('modal.ohrule.subtitle')"
    :dialog="true"
    @save="save"
    @close="close"
  >
    <template v-slot:body>
      <div class="ui-modal-feature__body">
        <ui-block id="name" :label="$t('modal.ohrule.name.label')">
          <template v-slot:body>
            <v-text-field
              v-model="data.name"
              id="ohRuleName"
              solo
              flat
              outlined
              hide-details
              :placeholder="$t('modal.ohrule.name.placeholder')"
            />
          </template>
        </ui-block>
        <ui-block id="weekdays" :label="$t('modal.ohrule.weekdays.label')">
          <template v-slot:body>
            <v-autocomplete
              v-model="data.weekdays"
              id="ohRuleWeekdays"
              solo
              flat
              outlined
              hide-details
              multiple
              chips
              deletable-chips
              hide-no-data
              clearable
              item-text="label"
              item-value="key"
              :placeholder="$t('modal.ohrule.weekdays.placeholder')"
              :items="days"
            />
          </template>
        </ui-block>
      </div>
    </template>
  </ui-modal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import UiModal from '@/components/UI/Modal.vue'
import UiBlock from '@/components/UI/Block.vue'

export default {
  name: 'UiModalFeature',
  components: {
    UiModal,
    UiBlock,
  },
  mixins: [validationMixin],
  data: () => ({
    data: {},
  }),
  computed: {
    days() {
      return [
        {
          id: 0,
          label: this.$t('common.days.monday'),
          key: 'monday',
        },
        {
          id: 1,
          label: this.$t('common.days.tuesday'),
          key: 'tuesday',
        },
        {
          id: 2,
          label: this.$t('common.days.wednesday'),
          key: 'wednesday',
        },
        {
          id: 3,
          label: this.$t('common.days.thursday'),
          key: 'thursday',
        },
        {
          id: 4,
          label: this.$t('common.days.friday'),
          key: 'friday',
        },
        {
          id: 5,
          label: this.$t('common.days.saturday'),
          key: 'saturday',
        },
        {
          id: 6,
          label: this.$t('common.days.sunday'),
          key: 'sunday',
        },
      ]
    },
  },
  methods: {
    save() {
      this.$emit('save', this.data)
    },
    close() {
      this.$emit('close')
    },
  },
  validations() {
    return {
      dataModel: {
        tag: {
          required,
        },
        featureKey: {
          required,
        },
        // list: {
        //   required,
        // },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.ui-modal-feature {
  &__body {
    @apply tw-flex tw-flex-col tw-gap-4;
  }
}
</style>
