<template>
  <div class="client-product-locator">
    <UiContainer large with-actions>
      <form class="client-product-locator__form" @submit.prevent="onSubmit" novalidate autocomplete="off">
        <section class="client-product-locator__form__section">
          <!-- <UiTitle :title="$t('clients.id.productLocator.name')"  -->
          <ui-switch
            :label="$t('clients.id.productLocator.status.title')"
            :switch-status="productLocatorStatus"
            :icon="icons.mdiTruckFast"
            @onChange="updateProductLocatorStatus"
          ></ui-switch>
        </section>
        <section class="client-product-locator__form__section">
          <UiTitle :title="$t('clients.id.productLocator.general.title')" />

          <div class="client-product-locator__form__section__grid">
            <ui-block
              id="authorizeFromLabel"
              class="required-asterisk"
              :label="$t('clients.id.productLocator.general.authorizeFrom.label')"
            >
              <template v-slot:body>
                <v-text-field
                  v-model="clientModel.leadProductAuthorizeFrom"
                  id="authorizeFrom"
                  solo
                  flat
                  outlined
                  :placeholder="$t('clients.id.productLocator.general.authorizeFrom.placeholder')"
                  :prepend-inner-icon="icons.mdiAccount"
                  :error-messages="leadProductAuthorizeFromRulesErrors"
                />
              </template>
            </ui-block>
            <ui-block
              id="productLocatorReplyToLabel"
              class="required-asterisk"
              :label="$t('clients.id.productLocator.general.replyTo.label')"
            >
              <template v-slot:body>
                <v-text-field
                  v-model="clientModel.productLocatorReplyTo"
                  id="replyTo"
                  solo
                  flat
                  outlined
                  :placeholder="$t('clients.id.productLocator.general.replyTo.placeholder')"
                  :prepend-inner-icon="icons.mdiEmail"
                  :error-messages="productLocatorReplyToRulesErrors"
                />
              </template>
            </ui-block>
          </div>
        </section>
        <section class="client-product-locator__form__section">
          <UiTitle :title="$t('clients.id.productLocator.send.title')" />
          <div class="client-product-locator__form__section__grid">
            <ui-block id="productLocatorFeedbackBlock" :label="$t('clients.id.productLocator.send.feedback.label')">
              <template v-slot:body>
                <v-text-field
                  @input="clientModel.productLocatorCustomerFeedbackDuration = $event !== '' ? Number($event) : null"
                  :rules="[isNumber, isInRange, isInteger]"
                  class="tw-mt-1"
                  :value="clientModel.productLocatorCustomerFeedbackDuration"
                  id="productLocatorFeedback"
                  solo
                  flat
                  outlined
                  :placeholder="$t('clients.id.productLocator.send.feedback.placeholder')"
                  type="number"
                  min="0"
                  max="10000"
                  :error-messages="productLocatorFeedbackRulesErrors('productLocatorCustomerFeedbackDuration')"
                />
              </template>
            </ui-block>
            <ui-block
              id="productLocatorReminderFirstBlock"
              :label="$t('clients.id.productLocator.send.reminderFirst.label')"
            >
              <template v-slot:body>
                <v-text-field
                  :rules="[isNumber, isInRange, isInteger]"
                  class="tw-mt-1"
                  :value="clientModel.productLocatorFirstReminderDuration"
                  id="productLocatorReminderFirst"
                  solo
                  flat
                  outlined
                  :placeholder="$t('clients.id.productLocator.send.reminderFirst.placeholder')"
                  type="number"
                  min="0"
                  max="10000"
                  @input="clientModel.productLocatorFirstReminderDuration = $event !== '' ? Number($event) : null"
                  :error-messages="productLocatorFeedbackRulesErrors('productLocatorFirstReminderDuration')"
                />
              </template>
            </ui-block>
            <ui-block
              id="productLocatorReminderLastBlock"
              :label="$t('clients.id.productLocator.send.reminderLast.label')"
            >
              <template v-slot:body>
                <v-text-field
                  :rules="[isNumber, isInRange, isInteger]"
                  class="tw-mt-1"
                  v-model.number="clientModel.productLocatorLastReminderDuration"
                  id="productLocatorReminderLast"
                  solo
                  flat
                  outlined
                  :placeholder="$t('clients.id.productLocator.send.reminderLast.placeholder')"
                  type="number"
                  min="0"
                  max="10000"
                  @input="clientModel.productLocatorLastReminderDuration = $event !== '' ? Number($event) : null"
                  :error-messages="productLocatorFeedbackRulesErrors('productLocatorLastReminderDuration')"
                />
              </template>
            </ui-block>
            <ui-block
              id="productLocatorLeadAcceptedBlock"
              :label="$t('clients.id.productLocator.send.leadAccepted.label')"
            >
              <template v-slot:body>
                <v-text-field
                  :rules="[isNumber, isInRange, isInteger]"
                  class="tw-mt-1"
                  v-model.number="clientModel.productLocatorLeadAcceptedDuration"
                  id="productLocatorLeadAccepted"
                  solo
                  flat
                  outlined
                  :placeholder="$t('clients.id.productLocator.send.leadAccepted.placeholder')"
                  type="number"
                  min="0"
                  max="10000"
                  @input="clientModel.productLocatorLeadAcceptedDuration = $event !== '' ? Number($event) : null"
                  :error-messages="productLocatorFeedbackRulesErrors('productLocatorLeadAcceptedDuration')"
                />
              </template>
            </ui-block>
            <ui-block
              id="productLocatorLeadFirstNotifsBlock"
              :label="$t('clients.id.productLocator.send.leadFirstNotifs.label')"
            >
              <template v-slot:body>
                <v-text-field
                  :rules="[isNumber, isInRange, isInteger]"
                  class="tw-mt-1"
                  v-model.number="clientModel.productLocatorLeadFirstNotifsDelayingDuration"
                  id="productLocatorLeadFirstNotifs"
                  solo
                  flat
                  outlined
                  :placeholder="$t('clients.id.productLocator.send.leadFirstNotifs.placeholder')"
                  type="number"
                  min="0"
                  max="10000"
                  @input="
                    clientModel.productLocatorLeadFirstNotifsDelayingDuration = $event !== '' ? Number($event) : null
                  "
                  :error-messages="productLocatorFeedbackRulesErrors('productLocatorLeadFirstNotifsDelayingDuration')"
                />
              </template>
            </ui-block>
            <ui-block
              id="productLocatorLeadSingleMailBlock"
              :label="$t('clients.id.productLocator.send.leadSingleMail.label')"
            >
              <template v-slot:body>
                <v-text-field
                  :rules="[isNumber, isInRange, isInteger]"
                  class="tw-mt-1"
                  v-model.number="clientModel.productLocatorLeadSingleLeadByMailDuration"
                  id="productLocatorLeadSingleMail"
                  solo
                  flat
                  outlined
                  :placeholder="$t('clients.id.productLocator.send.leadSingleMail.placeholder')"
                  type="number"
                  min="0"
                  max="10000"
                  @input="
                    clientModel.productLocatorLeadSingleLeadByMailDuration = $event !== '' ? Number($event) : null
                  "
                  :error-messages="productLocatorFeedbackRulesErrors('productLocatorLeadSingleLeadByMailDuration')"
                />
              </template>
            </ui-block>
            <ui-block id="productLocatorLeadTokenBlock" :label="$t('clients.id.productLocator.send.leadToken.label')">
              <template v-slot:body>
                <v-text-field
                  :rules="[isNumber, isInRange, isInteger]"
                  class="tw-mt-1"
                  v-model.number="clientModel.productLocatorLeadTokenDuration"
                  id="productLocatorLeadToken"
                  solo
                  flat
                  outlined
                  :placeholder="$t('clients.id.productLocator.send.leadToken.placeholder')"
                  type="number"
                  min="0"
                  max="10000"
                  @input="clientModel.productLocatorLeadTokenDuration = $event !== '' ? Number($event) : null"
                  :error-messages="productLocatorFeedbackRulesErrors('productLocatorLeadTokenDuration')"
                />
              </template>
            </ui-block>
            <ui-block
              id="productLocatorLeadReassignBlock"
              :label="$t('clients.id.productLocator.send.leadReassign.label')"
            >
              <template v-slot:body>
                <v-text-field
                  :rules="[isNumber, isInRange, isInteger]"
                  class="tw-mt-1"
                  v-model.number="clientModel.productLocatorLeadReassignDuration"
                  id="productLocatorLeadReassign"
                  solo
                  flat
                  outlined
                  :placeholder="$t('clients.id.productLocator.send.leadReassign.placeholder')"
                  type="number"
                  min="0"
                  max="10000"
                  @input="clientModel.productLocatorLeadReassignDuration = $event !== '' ? Number($event) : null"
                  :error-messages="productLocatorFeedbackRulesErrors('productLocatorLeadReassignDuration')"
                />
              </template>
            </ui-block>
          </div>
        </section>
        <UiActions large centered>
          <v-btn type="submit" rounded color="primary" :loading="saving" :disabled="saving">
            {{ $t('button.save') }}
          </v-btn>
        </UiActions>
      </form>
    </UiContainer>
  </div>
</template>

<script>
import { mdiTruckFast, mdiAccount, mdiEmail } from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { clone } from '@/utils/utilities.util'
import UiActions from '@/components/UI/Actions.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiBlock from '@/components/UI/Block.vue'
import UiSwitch from '@/components/UI/Switch.vue'

export default {
  name: 'ClientProductLocator',
  components: {
    UiActions,
    UiContainer,
    UiTitle,
    UiBlock,
    UiSwitch,
  },
  mixins: [validationMixin],
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentClient: {
      type: Object,
      required: true,
    },
  },
  data: vm => ({
    icons: {
      mdiTruckFast,
      mdiAccount,
      mdiEmail,
    },
    clientModel: {},
    isNumber: value => (vm.checkIsNumberOrReset(value) ? true : vm.$t('clients.id.productLocator.send.error.number')),
    isInRange: value => (vm.checkIsInRangeOrReset(value) ? true : vm.$t('clients.id.productLocator.send.error.range')),
    isInteger: value =>
      vm.checkIsIntegerOrReset(value) ? true : vm.$t('clients.id.productLocator.send.error.decimal'),
  }),
  mounted() {
    this.clientModel = clone(this.currentClient)
  },
  computed: {
    productLocatorStatus() {
      return this.clientModel.productLocatorStatus === '1' ? true : false
    },
    leadProductAuthorizeFromRulesErrors() {
      const errors = []
      if (!this.$v.clientModel.leadProductAuthorizeFrom.$dirty) return errors
      !this.$v.clientModel.leadProductAuthorizeFrom.required && errors.push(this.$t('error.required'))
      return errors
    },
    productLocatorReplyToRulesErrors() {
      const errors = []
      if (!this.$v.clientModel.productLocatorReplyTo.$dirty) return errors
      !this.$v.clientModel.productLocatorReplyTo.required && errors.push(this.$t('error.required'))
      !this.$v.clientModel.productLocatorReplyTo.email && errors.push(this.$t('error.email'))
      return errors
    },
  },
  methods: {
    productLocatorFeedbackRulesErrors(variableName) {
      const errors = []
      if (!this.$v.clientModel[variableName].$dirty) return errors
      !this.$v.clientModel[variableName].isNumber && errors.push(this.$t('clients.id.productLocator.send.error.number'))
      !this.$v.clientModel[variableName].isRange && errors.push(this.$t('clients.id.productLocator.send.error.range'))
      !this.$v.clientModel[variableName].isInteger &&
        errors.push(this.$t('clients.id.productLocator.send.error.decimal'))
      return errors
    },
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('updateClient', { client: this.clientModel })
        this.$v.$reset()
      }
    },
    updateProductLocatorStatus(status) {
      this.clientModel.productLocatorStatus = status ? '1' : '0'
    },
    checkIsNumberOrReset(value) {
      return value >= 0 ? typeof Number(value) === 'number' : true
    },
    checkIsInRangeOrReset(value) {
      if (!value && parseInt(value) !== 0) {
        return true
      }
      return value >= 0 ? value > 0 && value <= 10000 : true
    },
    checkIsIntegerOrReset(value) {
      return value >= 0 ? Number.isInteger(Number(value)) : true
    },
  },
  validations() {
    return {
      clientModel: {
        productLocatorStatus: {
          required,
        },
        leadProductAuthorizeFrom: {
          required,
        },
        productLocatorReplyTo: {
          required,
          email,
        },
        productLocatorCustomerFeedbackDuration: {
          isNumber: value => this.checkIsNumberOrReset(value),
          isRange: value => this.checkIsInRangeOrReset(value),
          isInteger: value => this.checkIsIntegerOrReset(value),
        },
        productLocatorFirstReminderDuration: {
          isNumber: value => this.checkIsNumberOrReset(value),
          isRange: value => this.checkIsInRangeOrReset(value),
          isInteger: value => this.checkIsIntegerOrReset(value),
        },
        productLocatorLastReminderDuration: {
          isNumber: value => this.checkIsNumberOrReset(value),
          isRange: value => this.checkIsInRangeOrReset(value),
          isInteger: value => this.checkIsIntegerOrReset(value),
        },
        productLocatorLeadAcceptedDuration: {
          isNumber: value => this.checkIsNumberOrReset(value),
          isRange: value => this.checkIsInRangeOrReset(value),
          isInteger: value => this.checkIsIntegerOrReset(value),
        },
        productLocatorLeadFirstNotifsDelayingDuration: {
          isNumber: value => this.checkIsNumberOrReset(value),
          isRange: value => this.checkIsInRangeOrReset(value),
          isInteger: value => this.checkIsIntegerOrReset(value),
        },
        productLocatorLeadSingleLeadByMailDuration: {
          isNumber: value => this.checkIsNumberOrReset(value),
          isRange: value => this.checkIsInRangeOrReset(value),
          isInteger: value => this.checkIsIntegerOrReset(value),
        },
        productLocatorLeadTokenDuration: {
          isNumber: value => this.checkIsNumberOrReset(value),
          isRange: value => this.checkIsInRangeOrReset(value),
          isInteger: value => this.checkIsIntegerOrReset(value),
        },
        productLocatorLeadReassignDuration: {
          isNumber: value => this.checkIsNumberOrReset(value),
          isRange: value => this.checkIsInRangeOrReset(value),
          isInteger: value => this.checkIsIntegerOrReset(value),
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.client-product-locator {
  @apply tw-h-full;

  &__form {
    &__section {
      @apply tw-mt-12;
      &:first-child {
        @apply tw-mt-0;
      }

      &__status {
        @apply tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-6;

        &__left {
          @apply tw-flex tw-flex-row tw-items-center tw-gap-4;

          &__icon {
            @apply tw-rounded-full tw-p-1;

            border: 1px solid;
          }
        }
      }

      &__grid {
        @apply tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full tw-mt-6 tw-items-end;
      }
    }
  }
}

.active {
  color: var(--v-success-base);
}
.inactive {
  color: var(--v-error-base);
}
</style>
