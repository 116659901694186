<template>
  <div class="client-general">
    <UiContainer large with-actions>
      <form class="client-general__form" @submit.prevent="onSubmit" novalidate autocomplete="off">
        <section class="client-general__form__section">
          <UiTitle :title="$t('clients.id.general.general.title')" />
          <div class="tw-flex tw-justify-center tw-col-span-2 tw-mt-6">
            <ui-upload-file
              :current-image="clientLogo"
              :image="clientModel.logo"
              :avatar="clientModel.name"
              @onFileChange="onFileChange($event, 'clientLogo')"
              @resetClientLogo="resetCurrentFile('clientLogo')"
              @deleteLogoFile="() => (clientModel.logo = '') && resetCurrentFile('clientLogo')"
            />
          </div>
          <ui-switch
            :label="$t('clients.id.general.general.ampm.label')"
            :switch-status="ampmStatus"
            :icon="icons.mdiHours24"
            @onChange="updateAmpmStatus"
          />
          <div class="client-general__form__section__grid">
            <ui-block id="nameLabel" class="required-asterisk" :label="$t('clients.id.general.general.name.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="clientModel.name"
                  id="name"
                  solo
                  flat
                  outlined
                  :placeholder="$t('clients.id.general.general.name.placeholder')"
                  :prepend-inner-icon="icons.mdiDomain"
                  :error-messages="nameErrors"
                />
              </template>
            </ui-block>
            <ui-block id="locationTypesLabel" :label="$t('clients.id.general.general.locationTypes.label')">
              <template v-slot:body>
                <v-combobox
                  v-model="clientModel.locationTypes"
                  id="locationTypes"
                  solo
                  flat
                  outlined
                  multiple
                  chips
                  deletable-chips
                  hide-no-data
                  :label="$t('clients.id.general.general.locationTypes.placeholder')"
                  :prepend-inner-icon="icons.mdiOfficeBuildingCog"
                  :items="currentClient.locationTypes"
                  :error-messages="locationTypesErrors"
                />
              </template>
            </ui-block>
            <ui-block id="gatoreviewsIdLabel" :label="$t('clients.id.general.general.gatoreviewsId.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="clientModel.gatoreviewsId"
                  id="gatoreviewsId"
                  solo
                  flat
                  outlined
                  :placeholder="$t('clients.id.general.general.gatoreviewsId.placeholder')"
                  :prepend-inner-icon="icons.mdiKey"
                />
              </template>
            </ui-block>
            <ui-block id="s3Path" :label="$t('clients.id.general.general.mediaPath.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="clientModel.s3Path"
                  id="s3PathLabel"
                  solo
                  flat
                  outlined
                  :prepend-inner-icon="icons.mdiLinkVariant"
                  :disabled="true"
                />
              </template>
            </ui-block>
            <ui-block
              class="required-asterisk sm:tw-col-span-2"
              id="customRulesLabel"
              :label="$t('clients.id.general.general.customRules.label')"
            >
              <template v-slot:body>
                <v-textarea
                  :value="customRulesModel"
                  id="customRules"
                  solo
                  flat
                  outlined
                  auto-grow
                  no-resize
                  :placeholder="$t('clients.id.general.general.customRules.placeholder')"
                  :prepend-inner-icon="icons.mdiNoteEdit"
                  :error-messages="customRulesErrors"
                  @input="setCustomRulesModel"
                />
              </template>
            </ui-block>
          </div>
        </section>
        <section class="client-general__form__section">
          <UiTitle :title="$t('clients.id.general.language.title')" />

          <div class="client-general__form__section__grid">
            <ui-block
              id="languageLabel"
              class="required-asterisk"
              :label="$t('clients.id.general.language.defaultLocale.label')"
            >
              <template v-slot:body>
                <v-autocomplete
                  v-model="clientModel.defaultLocaleId"
                  id="language"
                  solo
                  flat
                  outlined
                  item-value="id"
                  item-text="name"
                  :placeholder="$t('clients.id.general.language.defaultLocale.placeholder')"
                  :prepend-inner-icon="icons.mdiWeb"
                  :items="locales"
                  :error-messages="defaultLocaleIdErrors"
                />
              </template>
            </ui-block>
            <ui-block id="localesLabel" :label="$t('clients.id.general.language.locales.label')">
              <template v-slot:body>
                <v-autocomplete
                  class="client-general__form__section__grid__autocomplete"
                  ref="localesInput"
                  v-model="clientModel.locales"
                  id="locales"
                  solo
                  flat
                  outlined
                  multiple
                  hide-no-data
                  clearable
                  item-value="id"
                  item-text="name"
                  :full-width="true"
                  :placeholder="$t('clients.id.general.language.locales.placeholder')"
                  :prepend-inner-icon="icons.mdiWeb"
                  :items="localesNoDefault"
                >
                  <template v-slot:selection="{ item, parent }">
                    <v-chip small close @click:close="parent.selectItem(item)">
                      <span class="client-general__form__section__grid__autocomplete__chip">{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </template>
            </ui-block>
          </div>
        </section>
        <section class="client-general__form__section tw-mb-6">
          <UiTitle :title="$t('clients.id.general.featureList.title')" />

          <div class="tw-flex tw-flex-col tw-mt-6">
            <v-card
              v-for="feature in featuresListModel"
              :key="feature.id"
              class="client-general__form__section__rule"
              :class="{ 'client-general__form__section__rule--hidden': feature.tmpStatus === 'delete' }"
              hover
              @click="openModalFeature(feature)"
            >
              <v-icon>{{ icons.mdiSelectGroup }}</v-icon>
              <div>
                <UiLabel id="name" :label="feature.tag" />
                <UiSubtitle :subtitle="feature.list.join(', ')" />
              </div>
            </v-card>
            <v-btn
              class="tw-self-center tw-mt-4"
              color="primary"
              rounded
              outlined
              :disabled="!currentClient.locationOpeningHoursStatus"
              @click="openModalFeature()"
            >
              <v-icon dense left> {{ icons.mdiPlus }} </v-icon>
              {{ $t('clients.id.general.featureList.button') }}
            </v-btn>
          </div>
        </section>
        <UiActions large centered>
          <v-btn type="submit" rounded color="primary" :loading="saving" :disabled="saving">
            {{ $t('button.save') }}
          </v-btn>
        </UiActions>
      </form>
    </UiContainer>

    <ui-modal-feature
      v-if="editingModal === 'feature'"
      :data="modalData"
      :client-id="currentClient.id"
      :tmp-id="modalTmpId"
      :delete-btn="Object.keys(modalData).length > 0"
      @remove="removeModalFeature"
      @save="saveModalFeature"
      @close="closeModal"
    />
  </div>
</template>

<script>
import {
  mdiImageEdit,
  mdiImagePlus,
  mdiUndoVariant,
  mdiTrashCanOutline,
  mdiDomain,
  mdiOfficeBuildingCog,
  mdiKey,
  mdiLinkVariant,
  mdiNoteEdit,
  mdiWeb,
  mdiSelectGroup,
  mdiPlus,
  mdiHours24,
} from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { clone } from '@/utils/utilities.util'
import uploadFileMixin from '@/mixins/uploadFile.mixin'
import UiActions from '@/components/UI/Actions.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiLabel from '@/components/UI/Label.vue'
import UiModalFeature from '@/components/UI/Modal/Feature.vue'
import UiBlock from '@/components/UI/Block.vue'
import UiSwitch from '@/components/UI/Switch.vue'
import UiUploadFile from '@/components/UI/UploadFile.vue'

export default {
  name: 'ClientGeneral',
  components: {
    UiActions,
    UiContainer,
    UiTitle,
    UiSubtitle,
    UiLabel,
    UiSwitch,
    UiBlock,
    UiUploadFile,
    UiModalFeature,
  },
  mixins: [validationMixin, uploadFileMixin],
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentClient: {
      type: Object,
      required: true,
    },
    featuresList: {
      type: Array,
      required: true,
    },
    locales: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiImageEdit,
      mdiImagePlus,
      mdiUndoVariant,
      mdiTrashCanOutline,
      mdiDomain,
      mdiOfficeBuildingCog,
      mdiKey,
      mdiLinkVariant,
      mdiNoteEdit,
      mdiWeb,
      mdiSelectGroup,
      mdiPlus,
      mdiHours24,
    },
    clientLogo: {
      file: null,
      base64: '',
    },
    clientModel: {},
    featuresListModel: [],
    hover: false,
    editingModal: '',
    modalData: null,
    modalTmpId: 0,
    customRulesModel: null,
  }),
  mounted() {
    this.clientModel = clone(this.currentClient)
    this.featuresListModel = clone(this.featuresList)
    this.customRulesModel = JSON.stringify(this.clientModel.customRules)
  },
  watch: {
    featuresList() {
      this.featuresListModel = clone(this.featuresList)
    },
    'clientModel.defaultLocaleId'() {
      if (this.clientModel.defaultLocaleId && this.clientModel.locales) {
        this.clientModel.locales = this.clientModel.locales.filter(
          locale => this.clientModel.defaultLocaleId !== locale
        )
      }
    },
  },
  computed: {
    ampmStatus() {
      return this.clientModel.ampmformat === '1' ? true : false
    },
    localesNoDefault() {
      return this.locales.filter(locale => this.clientModel.defaultLocaleId !== locale.id)
    },
    nameErrors() {
      const errors = []
      if (!this.$v.clientModel.name.$dirty) return errors
      !this.$v.clientModel.name.required && errors.push(this.$t('error.required'))
      return errors
    },
    defaultLocaleIdErrors() {
      const errors = []
      if (!this.$v.clientModel.defaultLocaleId.$dirty) return errors
      !this.$v.clientModel.defaultLocaleId.required && errors.push(this.$t('error.required'))
      return errors
    },
    customRulesErrors() {
      const errors = []
      if (!this.$v.customRulesModel.$dirty) return errors
      !this.$v.customRulesModel.required && errors.push(this.$t('error.required'))
      !this.$v.customRulesModel.isJson && errors.push(this.$t('error.json'))
      return errors
    },
    locationTypesErrors() {
      const errors = []
      if (!this.$v.clientModel.locationTypes.$dirty) return errors
      !this.$v.clientModel.locationTypes.formatSlug && errors.push(this.$t('error.slug'))
      return errors
    },
  },
  methods: {
    setCustomRulesModel(value) {
      this.customRulesModel = value ? value : 'null'
    },
    updateAmpmStatus(value) {
      this.clientModel.ampmformat = value ? '1' : '0'
    },
    openModalFeature(feature = {}) {
      this.modalTmpId++
      this.modalData = feature
      this.editingModal = 'feature'
    },
    saveModalFeature(payload) {
      const feature = payload.data
      const existing = payload.existing

      if (feature.tmpStatus === 'update') {
        this.featuresListModel = this.featuresListModel.map(featureModel =>
          featureModel.id === feature.id ? feature : featureModel
        )
      } else if (existing) {
        this.featuresListModel = this.featuresListModel.map(featureModel =>
          featureModel.tmpId === feature.tmpId ? feature : featureModel
        )
      } else {
        this.featuresListModel.push(feature)
      }
      this.closeModal()
    },
    removeModalFeature(feature) {
      if (feature.tmpStatus === 'update') {
        this.featuresListModel = this.featuresListModel.map(featureModel => {
          if (featureModel.id === feature.id) {
            featureModel.tmpStatus = 'delete'
          }
          return featureModel
        })
      } else {
        this.featuresListModel.forEach((featureModel, i) => {
          if (featureModel.tmpId === feature.tmpId) {
            this.featuresListModel.splice(i, 1)
          }
        })
      }
      this.closeModal()
    },
    closeModal() {
      this.editingModal = ''
      this.modalData = {}
    },
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.clientModel.customRules = JSON.parse(this.customRulesModel)
        this.$emit('updateClient', {
          client: this.clientModel,
          clientLogo: this.clientLogo.base64,
          featuresList: this.featuresListModel,
        })
        this.$v.$reset()
      }
    },
    checkIfValidSlug(slugs) {
      const regexExp = /^[a-zA-z](-?[a-zA-Z])*$/
      let isInvalid = false
      slugs.forEach(slug => {
        if (regexExp.test(slug) == false) {
          isInvalid = true
        }
      })
      return !isInvalid
    },
  },
  validations() {
    return {
      clientModel: {
        name: {
          required,
        },
        defaultLocaleId: {
          required,
        },
        locationTypes: {
          formatSlug: slugs => this.checkIfValidSlug(slugs),
        },
      },
      customRulesModel: {
        required,
        isJson: json => {
          try {
            JSON.parse(json)
            return true
          } catch {
            return false
          }
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.client-general {
  @apply tw-h-full;

  &__form {
    &__section {
      @apply tw-mt-6;
      &:first-child {
        @apply tw-mt-0;
      }
      &__grid {
        @apply tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full tw-mt-6;

        &__autocomplete {
          &__chip {
            @apply tw-truncate;

            max-width: 10rem;
          }
        }
      }
      &__rule {
        @apply tw-flex tw-flex-row tw-gap-4 tw-my-2 tw-p-4 tw-cursor-pointer tw-shadow;

        &:first-child {
          @apply tw-mt-0;
        }

        &--hidden {
          @apply tw-hidden;
        }
      }
    }
  }
}
</style>
