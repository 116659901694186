<template>
  <div class="client-product-locator-seo">
    <UiContainer large with-actions>
      <div class="client-product-locator-seo__selector" :class="{ 'theme--dark': $vuetify.theme.dark }">
        <div class="client-product-locator-seo__selector__title">
          <UiTitle :title="$t('clients.id.productLocatorSeo.selector.title', { name: currentFrontOffice.name })" />
          <UiSubtitle
            :subtitle="$t('clients.id.productLocatorSeo.selector.subtitle', { lang: currentLang.localeName })"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-items-start tw-gap-1">
          <v-autocomplete
            v-model="selectedFO"
            id="FOs"
            solo
            flat
            dense
            outlined
            return-object
            hide-details
            item-text="name"
            :prepend-inner-icon="icons.mdiTranslate"
            :items="availablesFrontOffices"
            @change="FOUpdate"
          />
          <v-radio-group
            class="tw-mt-0"
            row
            v-model="currentLang"
            @change="algoliaModelHaveChanged ? (dialog.isOpen = true) : initialiseAlgoliaSeo()"
          >
            <v-radio v-for="locale in FOLocales" :key="locale.localeId" :label="locale.localeName" :value="locale" />
          </v-radio-group>
        </div>
        <v-dialog transition="dialog-top-transition" max-width="450" persistent v-model="dialog.isOpen">
          <template v-slot:default>
            <v-card>
              <v-toolbar class="tw-text-2xl tw-font-semibold" color="info" dark>
                {{ $t('clients.id.productLocatorSeo.selector.dialog.title') }}
              </v-toolbar>
              <v-card-text>
                <div class="tw-text-black tw-my-4">
                  {{ $t('clients.id.productLocatorSeo.selector.dialog.helper') }}
                </div>
                <v-alert v-if="numberOfNewGeneratePage" class="tw-text-xs" outlined dense type="success">
                  {{ $t('clients.id.productLocatorSeo.selector.dialog.newLink', { count: numberOfNewGeneratePage }) }}
                </v-alert>
                <v-alert v-if="numberOfDeletePage" class="tw-text-xs" dense type="error">
                  {{ $t('clients.id.productLocatorSeo.selector.dialog.deleteLink', { count: numberOfDeletePage }) }}
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="initialiseAlgoliaSeo" text>
                  {{ $t(`button.${dialog.type === 'save' ? 'close' : 'lose'}`) }}
                </v-btn>
                <v-btn text @click="onSubmit(dialog.type !== 'save', true)" color="success">
                  {{ $t('button.save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
      <v-divider class="tw-my-6" />
      <form
        class="client-product-locator-seo__form"
        @submit.prevent="onSubmit(false, false)"
        novalidate
        autocomplete="off"
      >
        <section class="client-product-locator-seo__form__section">
          <UiTitle :title="$t('clients.id.productLocatorSeo.random.title')" />

          <v-row class="client-product-locator-seo__form__section__content-seo">
            <v-col
              cols="5"
              :class="{ 'theme--dark': $vuetify.theme.dark }"
              class="client-product-locator-seo__form__section__content-seo__col client-product-locator-seo__form__section__content-seo__col--left"
            >
              <v-list class="client-product-locator-seo__form__section__content-seo__list">
                <v-list dense>
                  <v-list-item-group>
                    <v-list-item
                      @click="selectedContentSeoKey = key"
                      v-for="(item, key) in contentSEO"
                      :key="`expansion ${key}`"
                      :value="key"
                      two-line
                    >
                      <v-list-item-content
                        class="client-product-locator-seo__form__section__content-seo__list__variables"
                      >
                        <v-list-item-title>
                          {{ key }}
                        </v-list-item-title>
                        {{ variablesAvailable[key] }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-list>
            </v-col>
            <v-col
              :class="{ 'theme--dark': $vuetify.theme.dark }"
              class="client-product-locator-seo__form__section__content-seo__col client-product-locator-seo__form__section__content-seo__col--right"
            >
              <div class="tw-px-4 tw-py-4">
                <template v-if="selectedContentSeoKey">
                  <ui-block
                    v-for="(number, index) in 3"
                    :key="number"
                    :id="`randomFieldLabel${index}`"
                    :label="$t(`clients.id.productLocatorSeo.random.input.${number}`, { type: '' })"
                  >
                    <template v-slot:body>
                      <v-text-field
                        v-model="contentSEO[selectedContentSeoKey][index]"
                        :id="`randomField${number}`"
                        solo
                        flat
                        outlined
                        dense
                        placeholder=""
                      />
                    </template>
                  </ui-block>
                </template>
                <div class="tw-text-sm" v-else>
                  {{ $t('clients.id.productLocatorSeo.random.noData') }}
                </div>
              </div>
            </v-col>
          </v-row>
        </section>

        <section class="client-product-locator-seo__form__section">
          <UiTitle :title="$t('clients.id.productLocatorSeo.category.title')" />
          <div
            class="tw-bg-white tw-rounded-md tw-p-4 tw-mt-4 tw-flex tw-flex-col tw-items-start tw-justify-center"
            :class="{ 'tw-bg-gray-700': $vuetify.theme.dark }"
          >
            <UiSubtitle class="tw-text-black" large :subtitle="$t('clients.id.productLocatorSeo.category.system')" />
            <div
              v-html="$t('clients.id.productLocatorSeo.category.helper')"
              class="tw-ml-1 tw-mb-2 tw-text-xs tw-text-blue-500"
            />
            <div class="tw-flex tw-flew-row tw-justify-between tw-items-center tw-w-full">
              <div class="tw-flex tw-flew-row tw-items-end tw-gap-4">
                <v-icon v-if="isCategoriesAuto" color="green">{{ icons.mdiPodiumGold }}</v-icon>
                <v-icon v-else color="blue">{{ icons.mdiFormatListBulleted }}</v-icon>
                <div class="tw-text-sm">
                  {{ $t(`clients.id.productLocatorSeo.category.${isCategoriesAuto ? 'auto' : 'manual'}`) }}
                </div>
              </div>
              <v-switch :input-value="isCategoriesAuto" class="tw-mt-0" hide-details @change="updateCategories" />
            </div>
            <v-expand-transition>
              <div class="tw-w-full" v-if="!isCategoriesAuto">
                <v-divider class="tw-my-2" />
                <div>
                  <v-treeview
                    :selection-type="'independent'"
                    v-model="algoliaModel.categories"
                    :items="categoriesTreeviewOrder"
                    item-selectable
                    selectable
                    item-key="name"
                  />
                </div>
              </div>
            </v-expand-transition>
          </div>
        </section>

        <section class="client-product-locator-seo__form__section">
          <UiTitle :title="$t('clients.id.productLocatorSeo.general.title')" />
          <div class="client-product-locator-seo__form__section__grid">
            <ui-block id="websiteUrlLabel" :label="$t('clients.id.productLocatorSeo.general.websiteUrl.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="algoliaModel.websiteUrl"
                  id="websiteUrl"
                  solo
                  flat
                  outlined
                  dense
                  :placeholder="$t('clients.id.productLocatorSeo.general.websiteUrl.placeholder')"
                />
              </template>
            </ui-block>
            <ui-block id="foUrlLabel" :label="$t('clients.id.productLocatorSeo.general.foUrl.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="algoliaModel.foUrl"
                  id="foUrl"
                  solo
                  flat
                  outlined
                  dense
                  :placeholder="$t('clients.id.productLocatorSeo.general.foUrl.placeholder')"
                />
              </template>
            </ui-block>
            <ui-block id="featureLabel" :label="$t('clients.id.productLocatorSeo.general.feature')">
              <template v-slot:body>
                <v-autocomplete
                  v-model="algoliaModel.categoryForSearch"
                  id="feature"
                  solo
                  flat
                  dense
                  outlined
                  hide-details
                  item-text="label"
                  item-value="featureKey"
                  :prepend-inner-icon="icons.mdiClipboardTextSearch"
                  :items="featuresList"
                />
              </template>
            </ui-block>
          </div>
          <div class="tw-mt-6">
            <ui-block id="cityCountLabel" :label="$t('clients.id.productLocatorSeo.general.cityCount')">
              <template v-slot:body>
                <v-slider hide-details max="50" v-model="algoliaModel.cityCount" thumb-label="always" />
              </template>
            </ui-block>
          </div>
        </section>
        <section class="client-product-locator-seo__form__section">
          <UiTitle :title="$t('clients.id.productLocatorSeo.cities.title')" />

          <v-list class="tw-my-4 tw-rounded-md" dense>
            <v-list-group
              @click="getLocationInsideCity(city)"
              v-for="(city, index) in citiesAvailables"
              :key="`city ${index}`"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ city.value }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-progress-linear v-if="isLoadingLocations" indeterminate />
              <v-list-item v-else>
                <v-list dense>
                  <v-list-item v-for="(location, index) in locationsInCity" :key="`location ${index}`">
                    <v-list-item-content>
                      <v-list-item-title>{{ location.name }}</v-list-item-title>
                      <v-list-item-subtitle class="tw-text-xs">{{
                        location.formatted_address.join(', ')
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-item>
            </v-list-group>
          </v-list>
        </section>
        <UiActions large centered>
          <v-btn
            @click="refreshSeoData()"
            rounded
            color="success"
            :loading="saving"
            :disabled="algoliaModelHaveChanged || saving"
          >
            {{ $t('button.refresh') }}
          </v-btn>
          <v-btn type="submit" rounded color="primary" :loading="saving" :disabled="saving">
            {{ $t('button.save') }}
          </v-btn>
        </UiActions>
      </form>
    </UiContainer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiTranslate, mdiPodiumGold, mdiFormatListBulleted, mdiClipboardTextSearch } from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { clone } from '@/utils/utilities.util'
import { isEqual, difference } from 'lodash'

import UiActions from '@/components/UI/Actions.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiBlock from '@/components/UI/Block.vue'

const initDialog = () => ({
  isOpen: false,
  type: 'locale',
})

export default {
  name: 'ClientProductLocatorSeo',
  components: {
    UiActions,
    UiContainer,
    UiTitle,
    UiSubtitle,
    UiBlock,
  },
  mixins: [validationMixin],
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    configClientSeo: {
      type: Object,
      required: false,
    },
    facetsCategories: {
      type: Object,
      required: true,
    },
    currentFrontOffice: {
      type: Object,
      required: true,
    },
    availablesFrontOffices: {
      type: Array,
      required: true,
    },
    featuresList: {
      type: Array,
      required: true,
    },
    citiesAvailables: {
      type: Array,
      required: true,
    },
  },
  watch: {
    configClientSeo() {
      this.algoliaModel = clone(this.configClientSeo)
    },
    currentFrontOffice(fo) {
      this.currentLang = fo.locales[0]
    },
  },
  data: () => ({
    icons: {
      mdiTranslate,
      mdiPodiumGold,
      mdiFormatListBulleted,
      mdiClipboardTextSearch,
    },
    isLoadingLocations: false,
    selectedFO: null,
    algoliaModel: {},
    selectedContentSeoKey: '',
    dialog: initDialog(),
    currentLang: null,
    isCategoriesAuto: false,
    variablesAvailable: {
      contentMetaTitle: '{category} {city} {brand}',
      contentMetaDescription: '{category} {city} {brand}',
      contentTitle: '{category} {city} {brand}',
      contentDescription: '{category} {city} {brand}',
      contentTitleProduct: '{category} {city} {brand} {productCount}',
      contentDescription_product: '{category} {city} {brand} {productCount}',
      contentTitleLocation: '{category} {city} {brand} {locationCount}',
      contentDescription_location: '{category} {city} {brand} {locationCount}',
      contentTitleSearch: '{city} {brand}',
      contentDescription_search: '{city} {brand}',
      contentDescription_about: '{city} {brand}',
      contentFooterSeo: '{category}',
    },
  }),
  created() {
    this.selectedFO = this.currentFrontOffice
    this.currentLang = this.currentFrontOffice.locales[0]
    this.algoliaModel = clone(this.configClientSeo)
    if (!Number.isInteger(this.algoliaModel.cityCount)) this.algoliaModel.cityCount = 15

    const topCategories = this.categoryTopAutoSelect
    const categoriesClient = clone(this.configClientSeo.categories)
    if (isEqual(categoriesClient.sort(), topCategories.sort())) this.isCategoriesAuto = true
  },
  computed: {
    ...mapState({
      locationsInCity: state => state.algolia.locationsInCity,
    }),
    contentSEO() {
      return this.algoliaModel.contentSEO
    },
    FOLocales() {
      return this.currentFrontOffice.locales
    },
    algoliaModelHaveChanged() {
      return JSON.stringify(this.configClientSeo) !== JSON.stringify(this.algoliaModel)
    },
    categoryTopAutoSelect() {
      const topCategories = []
      // eslint-disable-next-line no-unused-vars
      for (const [key, facet] of Object.entries(this.facetsCategories)) {
        const facetKeys = Object.keys(facet)
        topCategories.push(
          facetKeys
            .sort(function (a, b) {
              return facet[b] - facet[a]
            })
            .slice(0, 3)
        )
      }
      return topCategories.flat()
    },
    categoriesTreeviewOrder() {
      const categories = []
      const keys = Object.keys(this.facetsCategories['hierarchicalCategories.lvl0'] || [])
      const keysLvl1 = Object.keys(this.facetsCategories?.['hierarchicalCategories.lvl1'] || [])

      keys.forEach(key => {
        categories.push({
          name: key,
          children: this.getChilds(key, keysLvl1),
        })
      })
      return categories
    },
    percentOfPageChange() {
      const numbBasePages = this.configClientSeo.categories.length * this.configClientSeo.cityCount
      return Math.round(((this.numberOfNewGeneratePage + this.numberOfDeletePage) * 100) / numbBasePages)
    },
    numberOfNewGeneratePage() {
      const { categories, cityCount } = this.algoliaModel
      const { categories: defaultCategories, cityCount: defaultCityCount } = this.configClientSeo
      const newCategories = difference(categories, defaultCategories).length
      let pages = 0

      if (cityCount >= defaultCityCount) {
        if (!newCategories) return (cityCount - defaultCityCount) * categories.length
        pages += newCategories * defaultCityCount
        pages += (cityCount - defaultCityCount) * categories.length
      } else {
        if (!newCategories) return 0
        pages += newCategories * cityCount
      }

      return pages
    },
    numberOfDeletePage() {
      const { categories, cityCount } = this.algoliaModel
      const { categories: defaultCategories, cityCount: defaultCityCount } = this.configClientSeo
      const deleteCategories = difference(defaultCategories, categories).length
      let pages = 0

      if (cityCount <= defaultCityCount) {
        if (!deleteCategories) return (defaultCityCount - cityCount) * categories.length
        pages += deleteCategories * defaultCityCount
        pages += (defaultCityCount - cityCount) * categories.length
      } else {
        if (!deleteCategories) return 0
        pages += deleteCategories * defaultCityCount
      }

      return pages
    },
  },
  methods: {
    ...mapActions({
      getLocationsInCity: 'algolia/getLocationsInCity',
    }),
    updateCategories(isAuto) {
      if (isAuto) {
        this.algoliaModel.categories = this.categoryTopAutoSelect
        this.isCategoriesAuto = true
      } else {
        this.algoliaModel.categories = []
        this.isCategoriesAuto = false
      }
    },
    getChilds(parentKey, keysLvl1) {
      const childs = []
      keysLvl1.forEach(key => {
        const cleanKey = key.split('>')[0]
        if (cleanKey.includes(parentKey)) {
          childs.push({ name: key })
        }
      })
      return childs
    },
    async getLocationInsideCity(item) {
      this.isLoadingLocations = true
      await this.getLocationsInCity(item.value)
      this.isLoadingLocations = false
    },
    async FOUpdate() {
      this.dialog.type = 'FO'
      if (this.algoliaModelHaveChanged) this.dialog.isOpen = true
      else this.initialiseAlgoliaSeo()
    },
    initialiseAlgoliaSeo() {
      if (this.dialog.type === 'FO') this.$emit('updateCurrentFrontOffice', this.selectedFO)
      else if (this.dialog.type === 'locale')
        this.$emit('initialiseAlgoliaSeo', { locale: this.currentLang.localeCode })
      this.resetDefaultVariables()
    },
    refreshSeoData() {
      this.$emit('refreshSeoData')
    },
    onSubmit(initAlgoliaIndex, fromModal) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.percentOfPageChange > 10 && !fromModal) this.dialog = { isOpen: true, type: 'save' }
        else this.saveAlgolia(initAlgoliaIndex)
      }
    },
    saveAlgolia(initAlgoliaIndex) {
      this.$emit('saveAlgolia', {
        algoliaModel: this.algoliaModel,
        initAlgoliaIndex,
        locale: this.dialog.type === 'FO' ? this.selectedFO.localeCode : this.currentLang.localeCode,
        frontOffice: this.selectedFO,
      })
      this.resetDefaultVariables()
      this.$v.$reset()
    },
    resetDefaultVariables() {
      this.dialog = initDialog()
      this.selectedContentSeoKey = ''
    },
  },
  validations() {
    return {
      algoliaModel: {},
    }
  },
}
</script>

<style lang="scss" scoped>
.client-product-locator-seo {
  @apply tw-h-full tw-mb-8;

  &__selector {
    @apply tw-flex tw-flex-col tw-py-4 tw-px-12 tw-gap-2 tw-bg-gray-200 tw-rounded-md;
    &.theme--dark {
      @apply tw-bg-gray-700;
    }

    &__title {
      flex: 1 1 100%;
    }
  }

  &__form {
    &__section {
      @apply tw-mt-12;
      &:first-child {
        @apply tw-mt-0;
      }

      &__content-seo {
        @apply tw-my-4;
        height: 264px;

        &__list {
          @apply tw-h-full;
          overflow: scroll;

          &__variables {
            font-size: 0.7rem;
          }
        }

        &__col {
          @apply tw-p-0 tw-h-full;
          background-color: $white;
          &.theme--dark {
            @apply tw-bg-gray-700;
          }

          &--left {
            @apply tw-p-2 tw-pb-0 tw-rounded-l-md;
          }

          &--right {
            @apply tw-rounded-r-md;
          }
        }
      }

      &__grid {
        @apply tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full tw-mt-6 tw-items-end;
      }
    }
  }
}
</style>
