<template>
  <div class="client-synchro">
    <UiContainer large with-actions>
      <form class="client-synchro__form" @submit.prevent="onSubmit" novalidate autocomplete="off">
        <section class="client-synchro__form__section client-synchro__form__section__selector">
          <div class="client-synchro__form__section__selector__title">
            <UiTitle :title="$t('clients.id.synchro.gmbSynchroMode.title')" />
            <UiSubtitle :subtitle="$t('clients.id.synchro.gmbSynchroMode.label')" />
          </div>
          <v-autocomplete
            v-model="syncSettingsModel.gmbSynchroMode"
            id="gmbSynchroMode"
            solo
            flat
            dense
            outlined
            hide-details
            item-text="name"
            item-value="value"
            :prepend-inner-icon="icons.mdiTranslate"
            :items="sourcesItems"
            @change="resetSyncSettings(syncSettingsModel.gmbSynchroMode)"
          />
        </section>
        <section v-if="sourceIsGmb" class="client-synchro__form__section">
          <UiTitle :title="$t('clients.id.synchro.partialImport.title')" />
          <v-switch v-model="syncSettingsModel.partialImport">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.partialImport === true }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.partialImport.label')"
              />
            </template>
          </v-switch>
        </section>
        <section v-if="sourceIsLpe" class="client-synchro__form__section">
          <UiTitle :title="$t('clients.id.synchro.validate.title')" />
          <v-switch v-model="syncSettingsModel.validateOnly">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.validateOnly === true }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.validate.simulation.label')"
              />
            </template>
          </v-switch>
        </section>
        <section v-if="sourceIsLpe || sourceIsGmb" class="client-synchro__form__section">
          <UiTitle :title="$t('clients.id.synchro.fields.title')" />
          <UiSubtitle :subtitle="$t('clients.id.synchro.fields.label')" />
          <v-checkbox class="tw-mt-0" value="externalId" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('externalId') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.external')"
              />
            </template>
          </v-checkbox>
          <v-checkbox value="name" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('name') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.name')"
              />
            </template>
          </v-checkbox>
          <v-checkbox value="phone" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('phone') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.phone')"
              />
            </template>
          </v-checkbox>
          <v-checkbox value="description" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('description') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.description')"
              />
            </template>
          </v-checkbox>
          <v-checkbox value="street1" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('street1') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.street1')"
              />
            </template>
          </v-checkbox>
          <v-checkbox value="street2" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('street2') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.street2')"
              />
            </template>
          </v-checkbox>
          <v-checkbox value="postalCode" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('postalCode') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.postalCode')"
              />
            </template>
          </v-checkbox>
          <v-checkbox value="city" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('city') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.city')"
              />
            </template>
          </v-checkbox>
          <v-checkbox value="openingHours" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('openingHours') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.openingHours')"
              />
            </template>
          </v-checkbox>
          <v-checkbox value="exceptionalOpeningHours" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('exceptionalOpeningHours') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.exceptionalOpeningHours')"
              />
            </template>
          </v-checkbox>
          <v-checkbox value="openDate" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('openDate') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.openDate')"
              />
            </template>
          </v-checkbox>
          <v-checkbox value="website" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('website') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.website')"
              />
            </template>
          </v-checkbox>
          <v-checkbox v-if="sourceIsLpe" value="chain" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('chain') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.chain')"
              />
            </template>
          </v-checkbox>
          <v-checkbox value="gUpdates" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('gUpdates') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.gUpdates')"
              />
            </template>
          </v-checkbox>
          <v-checkbox value="gAttributeUpdates" class="tw-mt-0" v-model="syncSettingsModel.synchroGBMFields">
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('gAttributeUpdates') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.gAttributeUpdates')"
              />
            </template>
          </v-checkbox>
          <v-checkbox
            v-if="sourceIsGmb"
            value="coverPicture"
            class="tw-mt-0"
            v-model="syncSettingsModel.synchroGBMFields"
          >
            <template v-slot:label>
              <span
                :class="{ active: syncSettingsModel.synchroGBMFields.includes('coverPicture') }"
                class="tw-ml-2 tw-text-sm"
                v-html="$t('clients.id.synchro.fields.coverPicture')"
              />
            </template>
          </v-checkbox>
        </section>
        <section v-if="sourceIsLpe || sourceIsGmb" class="client-synchro__form__section">
          <UiTitle :title="$t('clients.id.synchro.customLink.title')" />
          <UiSubtitle :subtitle="$t('clients.id.synchro.customLink.label')" />
          <v-radio-group v-model="synchroGMBCustomLink">
            <v-radio :value="0">
              <template v-slot:label>
                <span
                  :class="{ active: synchroGMBCustomLink === 0 }"
                  class="tw-ml-2 tw-text-sm"
                  v-html="$t('clients.id.synchro.customLink.empty')"
                />
              </template>
            </v-radio>
            <v-radio value="website">
              <template v-slot:label>
                <span
                  :class="{ active: synchroGMBCustomLink === 'website' }"
                  class="tw-ml-2 tw-text-sm"
                  v-html="$t('clients.id.synchro.customLink.website')"
                />
              </template>
            </v-radio>
            <v-radio value="custom">
              <template v-slot:label>
                <span
                  :class="{ active: synchroGMBCustomLink === 'custom' }"
                  class="tw-ml-2 tw-text-sm"
                  v-html="$t('clients.id.synchro.customLink.custom')"
                />
              </template>
            </v-radio>
            <v-radio value="locator" @click="preFillSynchroGMBCustomLink">
              <template v-slot:label>
                <span
                  :class="{ active: synchroGMBCustomLink === 'locator' }"
                  class="tw-ml-2 tw-text-sm"
                  v-html="$t('clients.id.synchro.customLink.locator')"
                />
              </template>
            </v-radio>
            <div v-if="synchroGMBCustomLink === 'locator'" class="tw-flex tw-flex-row tw-items-center tw-gap-x-12">
              <v-btn
                @click="editUrlLocator"
                class="tw-flex tw-justify-start"
                color="#449afd"
                x-small
                outlined
                elevation="0"
              >
                {{ $t('clients.id.synchro.customLink.changeLocator') }}
              </v-btn>
              <div class="tw-text-xs tw-text-gray-600">
                {{ generateUrl }}
              </div>
            </div>
          </v-radio-group>
        </section>
        <section v-if="sourceIsLpe" class="client-synchro__form__section">
          <UiTitle :title="$t('clients.id.synchro.placeActionLinks.title')" />
          <UiSubtitle :subtitle="$t('clients.id.synchro.placeActionLinks.label')" />
          <v-radio-group v-model="synchroGMBPlaceActionLinks">
            <v-radio :value="''">
              <template v-slot:label>
                <span
                  :class="{ active: synchroGMBPlaceActionLinks === 0 }"
                  class="tw-ml-2 tw-text-sm"
                  v-html="$t('clients.id.synchro.placeActionLinks.empty')"
                />
              </template>
            </v-radio>
            <v-radio value="custom">
              <template v-slot:label>
                <span
                  :class="{ active: synchroGMBPlaceActionLinks === 'custom' }"
                  class="tw-ml-2 tw-text-sm"
                  v-html="$t('clients.id.synchro.placeActionLinks.custom')"
                />
              </template>
            </v-radio>
          </v-radio-group>
        </section>
        <section v-if="sourceIsGmb" class="client-synchro__form__section">
          <UiTitle :title="$t('clients.id.synchro.synchroGMBBrandsName.title')" />
          <UiSubtitle :subtitle="$t('clients.id.synchro.synchroGMBBrandsName.label')" />
          <v-combobox
            class="tw-my-4 tw-max-w-2xl"
            v-model="syncSettingsModel.synchroGMBBrandsName"
            chips
            deletable-chips
            multiple
            solo
          />
        </section>
        <section v-if="sourceIsGmb" class="client-synchro__form__section">
          <UiTitle :title="$t('clients.id.synchro.clientLocationType.title')" />
          <UiSubtitle :subtitle="$t('clients.id.synchro.clientLocationType.label')" />
          <v-autocomplete
            v-model="syncSettingsModel.clientLocationType"
            id="clientLocationType"
            solo
            flat
            outlined
            dense
            :items="currentClient.locationTypes"
            :placeholder="$t('clients.id.synchro.clientLocationType.placeholder')"
          />
        </section>
        <UiActions large centered>
          <v-btn type="submit" rounded color="primary" :loading="saving" :disabled="saving">
            {{ $t('button.save') }}
          </v-btn>
        </UiActions>
      </form>
    </UiContainer>

    <ui-modal-url-locator
      v-if="editingModal === 'urlLocator'"
      :data="syncSettingsModel.synchroGMBLocator"
      :availables-front-offices="availablesFrontOffices"
      @close="closeModal"
      @save="saveSynchroGMBLocator"
    />
  </div>
</template>

<script>
import { mdiNoteEdit } from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import UiActions from '@/components/UI/Actions.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiModalUrlLocator from '@/components/UI/Modal/UrlLocator.vue'

const initSyncSettings = () => ({
  partialImport: false,
  validateOnly: false,
  synchroGBMFields: [],
  synchroGMBCustomLink: '',
  synchroGMBPlaceActionLinks: '',
  gmbSynchroMode: '',
  synchroGMBBrandsName: [],
  clientLocationType: '',
  synchroGMBLocator: {
    foID: null,
    localeCode: '',
    suffix: '',
  },
})

export default {
  name: 'ClientSynchro',
  components: {
    UiActions,
    UiContainer,
    UiTitle,
    UiSubtitle,
    UiModalUrlLocator,
  },
  mixins: [validationMixin],
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentClient: {
      type: Object,
      required: true,
    },
    availablesFrontOffices: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiNoteEdit,
    },
    editingModal: '',
    syncSettingsModel: initSyncSettings(),
    sourcesItems: [
      { name: 'LPE', value: 'LPE' },
      { name: 'GMB', value: 'GMB' },
      { name: 'Empty', value: '' },
    ],
  }),
  created() {
    const {
      validateOnly,
      partialImport,
      gmbSynchroMode,
      synchroGMBBrandsName,
      clientLocationType,
      synchroGMBCustomLink,
      synchroGBMFields,
      synchroGMBPlaceActionLinks,
      synchroGMBLocator,
    } = this.currentClientCustomRules

    this.syncSettingsModel = {
      ...this.syncSettingsModel,
      ...(partialImport && { partialImport }),
      ...(validateOnly && { validateOnly }),
      ...(gmbSynchroMode && { gmbSynchroMode }),
      ...(synchroGMBCustomLink && { synchroGMBCustomLink }),
      ...(synchroGBMFields && { synchroGBMFields }),
      ...(synchroGMBPlaceActionLinks && { synchroGMBPlaceActionLinks }),
      ...(synchroGMBBrandsName && { synchroGMBBrandsName }),
      ...(clientLocationType && { clientLocationType }),
      ...(synchroGMBLocator && { synchroGMBLocator }),
    }
  },
  computed: {
    currentClientCustomRules() {
      return this.currentClient.customRules || {}
    },
    sourceIsLpe() {
      return this.syncSettingsModel.gmbSynchroMode === 'LPE'
    },
    sourceIsGmb() {
      return this.syncSettingsModel.gmbSynchroMode === 'GMB'
    },
    synchroGMBCustomLink: {
      get() {
        return this.syncSettingsModel.synchroGMBCustomLink ? this.syncSettingsModel.synchroGMBCustomLink : 0
      },
      set(value) {
        this.syncSettingsModel.synchroGMBCustomLink = value === 0 ? '' : value
        return value
      },
    },
    synchroGMBPlaceActionLinks: {
      get() {
        return this.syncSettingsModel.synchroGMBPlaceActionLinks ? this.syncSettingsModel.synchroGMBPlaceActionLinks : 0
      },
      set(value) {
        this.syncSettingsModel.synchroGMBPlaceActionLinks = value === 0 ? '' : value
        return value
      },
    },
    generateUrl() {
      const FoSelect =
        this.availablesFrontOffices.find(fo => fo.id === this.syncSettingsModel.synchroGMBLocator.foID) || {}
      const synchroGMBLocator = this.syncSettingsModel.synchroGMBLocator
      return `${FoSelect.url || '{locatorUrl}'}/${synchroGMBLocator.localeCode || '{locale}'}/{locationID}?${
        synchroGMBLocator.suffix
      }`
    },
  },
  methods: {
    preFillSynchroGMBCustomLink() {
      const Fo = this.availablesFrontOffices[0] || {}
      const synchroGMBLocator = {
        foID: Fo.id,
        localeCode: Fo.locales[0].localeCode,
        suffix: '',
      }
      this.saveSynchroGMBLocator(synchroGMBLocator)
    },
    saveSynchroGMBLocator(synchroGMBLocator) {
      this.syncSettingsModel.synchroGMBLocator = synchroGMBLocator
      this.closeModal()
    },
    closeModal() {
      this.editingModal = ''
    },
    editUrlLocator() {
      this.editingModal = 'urlLocator'
    },
    resetSyncSettings(gmbSynchroMode) {
      this.syncSettingsModel = {
        ...initSyncSettings(),
        gmbSynchroMode,
      }
    },
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('updateSyncSettings', { customRules: this.syncSettingsModel })
        this.$v.$reset()
      }
    },
  },
  validations() {
    return {
      syncSettingsModel: {
        validateOnly: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.active {
  @apply tw-transition tw-duration-300;
  color: $blue-dodger;
}

.client-synchro {
  @apply tw-h-full;

  &__form {
    &__section {
      @apply tw-mt-6;
      &:first-child {
        @apply tw-mt-0;
      }

      &__grid {
        @apply tw-grid tw-gap-x-6 tw-w-full tw-mt-6;
      }

      &__selector {
        @apply tw-flex tw-items-center tw-p-4 tw-bg-gray-200 tw-rounded;
        &.theme--dark {
          @apply tw-bg-gray-700;
        }

        &__title {
          flex: 1 1 100%;
        }
      }
    }
  }
}
</style>
