<template>
  <ui-modal
    class="ui-modal-feature"
    :title="$t('modal.feature.title')"
    :subtitle="
      data.tag ? $t('modal.feature.subtitle.modify', { feature: data.tag }) : $t('modal.feature.subtitle.create')
    "
    :dialog="true"
    @save="save"
    @close="close"
  >
    <template v-slot:body>
      <div class="ui-modal-feature__body">
        <ui-block id="label" class="required-asterisk" :label="$t('modal.feature.label.label')">
          <template v-slot:body>
            <v-text-field
              v-model="dataModel.label"
              id="featureLabel"
              solo
              flat
              outlined
              :placeholder="$t('modal.feature.label.placeholder')"
              :error-messages="simpleErrors('label')"
            />
          </template>
        </ui-block>
        <ui-block id="tag" class="required-asterisk" :label="$t('modal.feature.tag.label')">
          <template v-slot:body>
            <v-text-field
              v-model="dataModel.tag"
              id="featureTag"
              solo
              flat
              outlined
              :placeholder="$t('modal.feature.tag.placeholder')"
              :error-messages="simpleErrors('tag')"
            />
          </template>
        </ui-block>
        <ui-block id="key" class="required-asterisk" :label="$t('modal.feature.featureKey.label')">
          <template v-slot:body>
            <v-text-field
              v-model="dataModel.featureKey"
              id="featureKey"
              solo
              flat
              outlined
              :placeholder="$t('modal.feature.featureKey.placeholder')"
              :error-messages="simpleErrors('featureKey')"
            />
          </template>
        </ui-block>
        <ui-block id="list" class="required-asterisk" :label="$t('modal.feature.list.label')">
          <template v-slot:body>
            <v-combobox
              v-model="dataModel.list"
              id="featureList"
              solo
              flat
              outlined
              multiple
              chips
              deletable-chips
              clearable
              hide-no-data
              :placeholder="$t('modal.feature.list.placeholder')"
              :error-messages="simpleErrors('list')"
            />
          </template>
        </ui-block>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn rounded plain @click="close">
        {{ $t('button.cancel') }}
      </v-btn>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded fab small color="error" v-bind="attrs" v-on="on" @click="remove" v-if="deleteBtn">
            <v-icon> {{ icons.mdiTrashCanOutline }} </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('button.delete') }}</span>
      </v-tooltip>
      <v-btn rounded color="primary" @click="save">
        {{ $t('button.save') }}
      </v-btn>
    </template>
  </ui-modal>
</template>

<script>
import { mdiTrashCanOutline } from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { clone } from '@/utils/utilities.util'
import UiModal from '@/components/UI/Modal.vue'
import UiBlock from '@/components/UI/Block.vue'

export default {
  name: 'UiModalFeature',
  components: {
    UiModal,
    UiBlock,
  },
  mixins: [validationMixin],
  props: {
    data: {
      type: Object,
      required: false,
    },
    clientId: {
      type: String,
      required: true,
    },
    tmpId: {
      type: Number,
      required: false,
    },
    deleteBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    icons: {
      mdiTrashCanOutline,
    },
    existing: false,
    dataModel: {},
  }),
  mounted() {
    this.dataModel = clone(this.data)
    if (Object.keys(this.dataModel).length > 0) {
      this.existing = true

      if (typeof this.dataModel.id !== 'undefined') {
        this.dataModel.tmpStatus = 'update'
      }
    } else {
      this.dataModel.tmpId = this.tmpId
      this.dataModel.clientId = this.clientId
      this.dataModel.tmpStatus = 'create'
    }
  },
  methods: {
    simpleErrors(fieldName) {
      const errors = []
      if (!this.$v.dataModel[fieldName].$dirty) return errors
      !this.$v.dataModel[fieldName].required && errors.push(this.$t('error.required'))
      return errors
    },
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('save', { data: this.dataModel, existing: this.existing })
      }
    },
    remove() {
      this.$emit('remove', this.dataModel)
    },
    close() {
      this.$emit('close')
    },
  },
  validations() {
    return {
      dataModel: {
        label: {
          required,
        },
        tag: {
          required,
        },
        featureKey: {
          required,
        },
        list: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.ui-modal-feature {
  &__body {
    @apply tw-flex tw-flex-col tw-gap-4;
  }
}
</style>
